var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full max-h-screen bg-background flex flex-col relative"},[_c('page-header',{attrs:{"title":"Incidents","backTo":"healthnsafety"}},[_c('router-link',{staticClass:"flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1",attrs:{"to":{ name: 'incident-create' }}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M12 5v14M5 12h14"}})]),_c('span',[_vm._v("Create Incident")])])],1),_c('main',{staticClass:"p-6 bg-background flex-1 overflow-y-scroll"},[_c('div',{staticClass:"w-full bg-white p-5 rounded shadow-md relative"},[_c('div',{staticClass:"w-full flex justify-between"},[_c('div',{staticClass:"flex -mx-2 mr-2"},[_c('button',{staticClass:"px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold",class:{
              'bg-orange-300 text-orange-900': _vm.currentTab == 'incidents',
              'hover:bg-gray-200': _vm.currentTab != 'incidents',
            },on:{"click":function($event){return _vm.selectTab('incidents')}}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"}}),_c('path',{attrs:{"d":"M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12"}})]),_c('span',[_vm._v("Incidents")])]),_c('button',{staticClass:"px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold",class:{
              'bg-orange-300 text-orange-900': _vm.currentTab == 'search',
              'hover:bg-gray-200': _vm.currentTab != 'search',
            },on:{"click":function($event){_vm.currentTab = 'search'}}},[_c('svg',{staticClass:"stroke-current h-5 w-5 mr-2",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('path',{attrs:{"d":"M21 21l-4.35-4.35"}})]),_c('span',[_vm._v("Search")])])]),_c('div',{staticClass:"flex justify-end flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing",attrs:{"type":"text","placeholder":"Search Incidents by Type, Location,Result, Illness,Treatment Received","title":"Products will be searched by: name, description & notes"},domProps:{"value":(_vm.searchTerm)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchIncidents.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_c('button',{staticClass:"rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner",on:{"click":_vm.searchIncidents}},[_c('svg',{staticClass:"stroke-current h-5 w-5",attrs:{"fill":"none","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","viewBox":"0 0 24 24"}},[_c('circle',{attrs:{"cx":"11","cy":"11","r":"8"}}),_c('path',{attrs:{"d":"M21 21l-4.35-4.35"}})])])])]),_c('div',{staticClass:"w-full mt-5"},[(_vm.currentTab == 'incidents')?[(_vm.viewState == 'Idle')?_c('div',[_c('vue-good-table',{staticClass:"mt-5",attrs:{"styleClass":"vgt-table condensed","mode":"remote","columns":_vm.columns,"rows":_vm.allIncidents,"totalRows":_vm.totalRecords,"row-style-class":_vm.markRowAsSelected,"pagination-options":{
                enabled: true,
                perPage: _vm.pagingParams.limit,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
              }},on:{"on-row-click":_vm.onIncidentRowSelected,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'incident_date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateAndTimeFn(props.row))+" ")]):(props.column.field == 'first_name')?_c('span',[_vm._v(" "+_vm._s(props.row.first_name)+" "+_vm._s(props.row.last_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2005003736)}),(_vm.allIncidents.length == 0)?_c('h5',{staticClass:"text-muted text-center pt-3 pb-3"},[_vm._v(" No products have been created yet ")]):_vm._e()],1):_vm._e()]:_vm._e(),(_vm.currentTab == 'search')?[_c('div',[(_vm.searchState == 'Idle')?_c('div',{staticClass:"mt-5"},[_c('h4',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.searchResults.length)+" search results for '"+_vm._s(_vm.searchTermWas)+"' ")]),_c('vue-good-table',{staticClass:"mt-5",attrs:{"mode":"remote","styleClass":"vgt-table condensed","rows":_vm.searchResults,"columns":_vm.columns,"totalRows":_vm.totalSearchResults,"row-style-class":_vm.markRowAsSelected,"pagination-options":{
                  enabled: true,
                  perPage: _vm.searchPagingParams.limit,
                  perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                }},on:{"on-row-click":_vm.onIncidentRowSelected,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'incident_date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateAndTimeFn(props.row))+" ")]):(props.column.field == 'first_name')?_c('span',[_vm._v(" "+_vm._s(props.row.first_name)+" "+_vm._s(props.row.last_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2005003736)}),(!_vm.searchResults || _vm.searchResults.length == 0)?_c('h5',{staticClass:"text-center py-3"},[_vm._v(" No results for "+_vm._s(_vm.searchTermWas)+" ")]):_vm._e()],1):_vm._e(),(_vm.searchState == 'Searching')?_c('div',{staticClass:"flex items-center justify-center py-20"},[_c('spinner',{attrs:{"color":'text-black',"size":10}})],1):_vm._e(),(_vm.searchState == 'Error')?_c('div',[_c('div',{staticClass:"alert alert-danger"},[_vm._v(" Something went wrong performing your search, please try again. If the problem persists, please contact your support team. ")])]):_vm._e()])]:_vm._e(),(_vm.viewState == 'Loading')?_c('div',{staticClass:"flex items-center justify-center py-20"},[_c('spinner',{attrs:{"color":'text-black',"size":10}})],1):_vm._e(),(_vm.viewState == 'Error')?_c('div',{staticClass:"flex items-center justify-center py-10"},[_c('div',{staticClass:"bg-red-200 rounded-lg w-full px-4 py-2"},[_c('h2',{staticClass:"text-red-600 text-xl font-bold"},[_vm._v(" "+_vm._s(_vm.error.status)+": Error ")]),_vm._m(0)])]):_vm._e()],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-red-800 text-lg"},[_vm._v(" There has been an error while trying to loading the incidents, please try again later. "),_c('br'),_vm._v("If the problem persists, please contact your support team. ")])}]

export { render, staticRenderFns }