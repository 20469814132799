<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Incidents" backTo="healthnsafety">
      <router-link
        :to="{ name: 'incident-create' }"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Create Incident</span>
      </router-link>
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex justify-between">
          <div class="flex -mx-2 mr-2">
            <button
              @click="selectTab('incidents')"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'incidents',
                'hover:bg-gray-200': currentTab != 'incidents',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path
                  d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                />
                <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12" />
              </svg>
              <span>Incidents</span>
            </button>
            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded mx-2 transition-colors duration-300 flex items-center font-semibold"
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex justify-end flex-1">
            <input
              type="text"
              class="ml-10 w-full lg:w-1/2 rounded-l bge-input bge-input-spacing"
              placeholder="Search Incidents by Type, Location,Result, Illness,Treatment Received"
              title="Products will be searched by: name, description &amp; notes"
              v-model="searchTerm"
              @keydown.enter="searchIncidents"
            />
            <button
              @click="searchIncidents"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div class="w-full mt-5">
          <template v-if="currentTab == 'incidents'">
            <div v-if="viewState == 'Idle'">
              <vue-good-table
                class="mt-5"
                styleClass="vgt-table condensed"
                mode="remote"
                :columns="columns"
                :rows="allIncidents"
                :totalRows="totalRecords"
                @on-row-click="onIncidentRowSelected"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                :row-style-class="markRowAsSelected"
                @on-sort-change="onSortChange"
                :pagination-options="{
                  enabled: true,
                  perPage: pagingParams.limit,
                  perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'incident_date'">
                    {{ formatDateAndTimeFn(props.row) }}
                  </span>
                  <span v-else-if="props.column.field == 'first_name'">
                    {{ props.row.first_name }} {{ props.row.last_name }}
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
              <h5
                class="text-muted text-center pt-3 pb-3"
                v-if="allIncidents.length == 0"
              >
                No products have been created yet
              </h5>
            </div>
          </template>
          <template v-if="currentTab == 'search'">
            <div>
              <div v-if="searchState == 'Idle'" class="mt-5">
                <h4 class="mb-5">
                  {{ searchResults.length }} search results for '{{
                    searchTermWas
                  }}'
                </h4>
                <vue-good-table
                  class="mt-5"
                  mode="remote"
                  styleClass="vgt-table condensed"
                  :rows="searchResults"
                  :columns="columns"
                  :totalRows="totalSearchResults"
                  @on-row-click="onIncidentRowSelected"
                  :row-style-class="markRowAsSelected"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                  @on-sort-change="onSortChange"
                  :pagination-options="{
                    enabled: true,
                    perPage: searchPagingParams.limit,
                    perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                    dropdownAllowAll: false,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'incident_date'">
                      {{ formatDateAndTimeFn(props.row) }}
                    </span>
                    <span v-else-if="props.column.field == 'first_name'">
                      {{ props.row.first_name }} {{ props.row.last_name }}
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                </vue-good-table>
                <h5
                  class="text-center py-3"
                  v-if="!searchResults || searchResults.length == 0"
                >
                  No results for {{ searchTermWas }}
                </h5>
              </div>
              <div
                v-if="searchState == 'Searching'"
                class="flex items-center justify-center py-20"
              >
                <spinner :color="'text-black'" :size="10" />
              </div>
              <div v-if="searchState == 'Error'">
                <div class="alert alert-danger">
                  Something went wrong performing your search, please try again.
                  If the problem persists, please contact your support team.
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="viewState == 'Loading'"
            class="flex items-center justify-center py-20"
          >
            <spinner :color="'text-black'" :size="10" />
          </div>
          <div
            v-if="viewState == 'Error'"
            class="flex items-center justify-center py-10"
          >
            <div class="bg-red-200 rounded-lg w-full px-4 py-2">
              <h2 class="text-red-600 text-xl font-bold">
                {{ error.status }}: Error
              </h2>
              <p class="text-red-800 text-lg">
                There has been an error while trying to loading the incidents,
                please try again later.
                <br />If the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>

        <!-- <div v-if="viewState == 'Loading'" class="absolute inset-0 bg-faint-white flex items-center justify-center">
        <spinner :color="'text-black'" :size="10" />
        </div>-->
      </div>
    </main>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "incidents",
  components: {
    PageHeader,
    Spinner,
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      currentTab: "incidents",
      searchState: "Pending",
      searchTerm: "",
      searchTermWas: "",
      allIncidents: [],
      columns: [
        {
          label: "Type",
          field: "type",
        },
        {
          label: "Date And Time",
          field: "incident_date",
        },
        {
          label: "Reported By",
          field: "reported_by",
          sortable: false,
        },
        {
          label: "Location",
          field: "location_name",
        },
        {
          label: "Result of Incident",
          field: "result_of_incident",
        },
        {
          label: "Treatment Received",
          field: "treatment_received",
          sortable: false,
        },
      ],
      totalRecords: 0,
      searchResults: [],
      pagingParams: {
        page: 1,
        limit: 50,
      },
      searchPagingParams: {
        page: 1,
        limit: 50,
      },
      totalSearchResults: 0,
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadIncidents();

      this.searchTerm = this.$store.state.incidentSearchTerm;
      await this.searchIncidents();

      this.viewState = "Idle";
    } catch (error) {
      this.error = error;
      this.viewState = "Error";
    }
  },
  methods: {
    selectTab: function (tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setIncidentSearchTerm", "");
    },

    formatDateAndTimeFn: function (rowObj) {
      if (rowObj.incident_date) {
        return this.$moment(rowObj.incident_date).format(
          "MMM DD, YYYY [at] hh:mm A",
        );
      } else {
        return "Date and Time not available";
      }
    },

    onIncidentRowSelected: function (selectedRow) {
      let incident_id = selectedRow.row.incident_id;
      this.$store.dispatch("setLastSelectedIncident", incident_id);
      this.$router.push({
        name: "incident-create",
        params: { incident_id: incident_id },
      });
    },

    async loadIncidents() {
      let incidentResult = await this.IncidentService.getIncidents(
        this.pagingParams,
      );

      incidentResult.data.forEach((element) => {
        element.date = this.$moment(element.date).format("DD/MM/YYYY");
      });

      this.allIncidents = incidentResult.data;
      this.totalRecords = incidentResult.count;
    },
    searchIncidents: async function () {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setIncidentSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let results = await this.IncidentService.searchIncident(
          this.searchTerm,
          this.searchPagingParams,
        );

        this.searchResults = results.data;
        this.totalSearchResults = results.count;
        this.searchTermWas = this.searchTerm;
        this.searchState = "Idle";
      } catch (err) {
        this.searchState = "Error";
      }
    },
    clearSearch: function () {
      this.$store.dispatch("setIncidentSearchTerm", "");
      this.searchResults = [];
      this.searchTermWas = "";
      this.searchTerm = "";
      this.searchState = "Pending";
      this.$nextTick(() => {
        this.currentTab = "incidents";
      });
    },
    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      if (this.currentTab == "search") {
        await this.searchIncidents();
      } else {
        await this.loadIncidents();
      }
    },

    updatePagingParams(newProps) {
      if (this.currentTab == "search") {
        this.searchPagingParams = Object.assign(
          {},
          this.searchPagingParams,
          newProps,
        );
      } else {
        this.pagingParams = Object.assign({}, this.pagingParams, newProps);
      }
    },
    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage });
      if (this.currentTab == "search") {
        await this.searchIncidents();
      } else {
        await this.loadIncidents();
      }
    },
    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
        page: 1,
      });

      if (this.currentTab == "search") {
        await this.searchIncidents();
      } else {
        await this.loadIncidents();
      }
    },

    markRowAsSelected: function (row) {
      return this.$store.state.lastSelectedContact == row.contact_id
        ? "bg-green-300"
        : "";
    },
  },
};
</script>

<style>
/* td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */
</style>
